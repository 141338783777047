var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{attrs:{"fill-height":"","fluid":""}},[_c('v-row',{staticClass:"mb-0",staticStyle:{"height":"150px"},attrs:{"align":"center","justify":"center"}},[_c('v-col',{staticClass:"mb-0 pa-1 pa-lg-5",attrs:{"cols":"6","lg":"3","align":"center","justify":"center"}},[_c('v-hover',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var hover = ref.hover;
return [_c('v-card',{staticClass:"py-2 rounded-xl",attrs:{"outlined":"","elevation":hover ? 1 : 0,"to":"/games"}},[_c('v-img',{attrs:{"alt":"Games","contain":"","src":"https://global-uploads.webflow.com/5c049f1efc73fb9007f7d7b6/5c186e2daa900a356fbc93e4_article-image-14-p-1080.png","transition":"scale-transition","height":"150"}}),_c('b',{staticClass:"red--text text--lighten-1"},[_vm._v("Games")])],1)]}}])})],1),_c('v-col',{staticClass:"mb-0 pa-1 pa-lg-5",attrs:{"cols":"6","lg":"3","align":"center","justify":"center"}},[_c('v-hover',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var hover = ref.hover;
return [_c('v-card',{staticClass:"py-2 rounded-xl",attrs:{"outlined":"","elevation":hover ? 1 : 0,"to":"/songs"}},[_c('v-img',{attrs:{"alt":"Songs","contain":"","src":"https://media.istockphoto.com/vectors/music-note-icon-vector-illustration-vector-id1175435360?k=6&m=1175435360&s=612x612&w=0&h=zuR82fJ-cZQpPZeIaUXAcQZ_jAXaVOSt-sWB6ycDpy0=","transition":"scale-transition","height":"150"}}),_c('b',{staticClass:"red--text text--lighten-1"},[_vm._v("Songs")])],1)]}}])})],1)],1),_c('v-row',{staticClass:"mt-10 mt-lg-0",staticStyle:{"height":"150px"},attrs:{"align":"center","justify":"center"}},[_c('v-col',{staticClass:"mt-0 pa-1 pa-lg-5",attrs:{"cols":"6","lg":"3","align":"center","justify":"center"}},[_c('v-hover',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var hover = ref.hover;
return [_c('v-card',{staticClass:"rounded-xl",attrs:{"outlined":"","elevation":hover ? 1 : 0,"to":"/talking","disabled":true}},[_c('v-card-title',{staticClass:"striped",staticStyle:{"margin-bottom":"-38px","z-index":"10","position":"relative","height":"55px"}},[(!_vm.$isMobile())?_c('p',[_vm._v("Under construction")]):_c('p',[_vm._v("Construction.")])]),_c('v-img',{staticClass:"mx-2",attrs:{"alt":"Talking","contain":"","src":"https://image.freepik.com/vrije-vector/teamwerkconcept-voor-bestemmingspagina_52683-18752.jpg","transition":"scale-transition","height":"150"}}),_c('b',{staticClass:"red--text text--lighten-1"},[_vm._v("Talking")])],1)]}}])})],1),_c('v-col',{staticClass:"mt-0 pa-1 pa-lg-5",attrs:{"cols":"6","lg":"3","align":"center","justify":"center"}},[_c('v-hover',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var hover = ref.hover;
return [_c('v-card',{staticClass:"py-2 rounded-xl",attrs:{"outlined":"","elevation":hover ? 1 : 0,"to":"/stories"}},[_c('v-img',{staticClass:"mx-2",attrs:{"alt":"Stories","contain":"","src":"https://static.vecteezy.com/ti/vecteur-libre/p3/495030-gens-qui-lisent-sur-une-grosse-pile-de-livre-avec-illustrationle-plane-arbre-fond-vectoriel.jpg","transition":"scale-transition","height":"150"}}),_c('b',{staticClass:"red--text text--lighten-1"},[_vm._v("Stories")])],1)]}}])})],1)],1),_c('v-row',{attrs:{"align":"center","justify":"center"}}),_c('v-row',{attrs:{"align":"center","justify":"center"}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }
<template>
  <v-app>
    <v-app-bar
      app
      color="red accent-4"
      dark
    >
      <div class="d-flex align-center">
        <!-- <v-img
          alt="Vuetify Logo"
          class="shrink mr-2"
          contain
          src="https://cdn.vuetifyjs.com/images/logos/vuetify-logo-dark.png"
          transition="scale-transition"
          width="40"
        /> -->
        <div v-if="this.$route.path == '/'" class="amber--text text--lighten-4 d-flex align-center" style="text-decoration:none;">
          <v-icon class="amber--text text--lighten-4 pl-1 pr-4">mdi-flower</v-icon>
          <h1 v-if="!$isMobile()">
            Maloha Chinese
          </h1>
          <h3 v-if="$isMobile()">
            Maloha Chinese
          </h3>
        </div>
        <router-link v-else-if="this.$route.path == '/about' || this.$route.path == '/shop'" class="amber--text text--lighten-4 d-flex align-center" to="/" style="text-decoration:none;">
          <v-icon class="amber--text text--lighten-4 pl-1 pr-lg-10 pr-4">mdi-keyboard-backspace</v-icon>
          <h1 v-if="!$isMobile()">
            {{this.$route.name}}
          </h1>
          <h3 v-if="$isMobile()">
            {{this.$route.name}}
          </h3>
        </router-link>
        <a v-else class="amber--text text--lighten-4 d-flex align-center" @click="$router.back() && stopPlaying()" style="text-decoration:none;">
          <v-icon class="amber--text text--lighten-4 pl-1 pr-lg-10 pr-4">mdi-keyboard-backspace</v-icon>
          <h1 v-if="!$isMobile()">
            {{this.$route.name}}
          </h1>
          <h3 v-if="$isMobile()">
            {{this.$route.name}}
          </h3>
        </a>
      </div>

      <v-spacer></v-spacer>

      <!-- <p class="animation">ANIMATION</p> -->

      <v-col v-if="$isMobile()" cols="1" class="text-center mt-0">
        <router-link to="/shop" style="text-decoration: none;">
          <h2 class="rounded-xl" :class="change ? 'animationMobile' : '' " style="width:70px; background-color: #FFECB3; color: #bd1300;">&#165; {{this.totalScore}}</h2>
        </router-link>
      </v-col>


      <!-- <div> -->
        <router-link v-if="!$isMobile()" class="rounded-xl router shop" align="center" justify="center" to="/shop">
        <div cols="1" class="text-center mt-0 mr-0" style="float:left"><h2 class="rounded-xl" :class="change ? 'animation' : 'points' ">&#165; {{this.totalScore}}</h2></div>
        <div class="amber--text text--lighten-4 px-5 rounded-xl" :class="change ? 'animation2' : 'shopPadding'" align="center" justify="center" style="text-decoration: none; float: left"><h3>Shop</h3></div>
        </router-link>

        <router-link v-if="!$isMobile()" class="rounded-xl amber--text text--lighten-4 mx-5 pa-1 router" to="/about" style="text-decoration: none;"><h3>Why Maloha?</h3></router-link>

      <!-- </div> -->

      <v-app-bar-nav-icon @click.stop="drawer = !drawer" v-if="$isMobile()" class="amber--text text--lighten-4 ml-15"></v-app-bar-nav-icon>

      <!-- <router-link v-if="!$isMobile()" class="amber--text text--darken-1 mx-5"  to="/"><h3>Home</h3></router-link>
      <router-link v-if="!$isMobile()" class="amber--text text--lighten-4 mx-5"  to="/games"><h3>Games</h3></router-link>
      <router-link v-if="!$isMobile()" class="amber--text text--lighten-4 mx-5"  to="/songs"><h3>Songs</h3></router-link>
      <router-link v-if="!$isMobile()" class="amber--text text--lighten-4 mx-5"  to="/talking"><h3>Talking</h3></router-link>
      <router-link v-if="!$isMobile()" class="amber--text text--lighten-4 mx-5"  to="/stories"><h3>Stories</h3></router-link> -->

      <v-col
        class="d-flex mt-5 hsk-style"
        cols="12"
        sm="1"
        v-if="!$isMobile()"
      >
        <v-select
          v-model="select"
          style="font-weight: bold; padding-bottom: 0px;"
          :items="items"
          item-text="name"
          item-value="number"
          @input="hskChoose(select.number)"
          color="#ffecb3"
          item-color="red"
          label="select"
          return-object
          single-line
        ></v-select>
      </v-col>
      

      <!-- <a v-if="!$isMobile()" href="mailto:Maloha<aan_marleen%40hotmail%2Ecom>?SUBJECT=Feedback%20Maloha&BODY=Thank%20you%20for%20sharing%20your%20feedback!%0D%0AWhat%20do%20you%20think%3F%0D%0A%0D%0AFeedback%3A" style="text-decoration:none;">
        <v-btn color="white" class="green--text">
          Feedback
        </v-btn>
      </a>
      <h4 v-if="!$isMobile()" class="ml-5">We appreciate it!</h4>  -->

      <!-- <v-btn
        href="https://github.com/vuetifyjs/vuetify/releases/latest"
        target="_blank"
        text
      >
        <span class="mr-2">Latest Release</span>
        <v-icon>mdi-open-in-new</v-icon>
      </v-btn> -->
    </v-app-bar>

     <v-navigation-drawer
      v-model="drawer"
      absolute
      top
      temporary
      style="z-index: 11;"
    >
      <v-list
        nav
        dense
      >
        <v-list-item-group
          v-model="group"
          active-class="red--text text--accent-4"
        >
          <!-- <v-list-item>
            <v-list-item-title>
              <router-link class="red--text text--darken-1 mx-5"  to="/"><h1>Home</h1></router-link>
            </v-list-item-title>
          </v-list-item>

          <v-list-item>
            <v-list-item-title>
              <router-link class="red--text text--lighten-3 mx-5"  to="/games"><h1>Games</h1></router-link>
            </v-list-item-title>
          </v-list-item>

          <v-list-item>
            <v-list-item-title>
              <router-link class="red--text text--lighten-3 mx-5"  to="/songs"><h1>Songs</h1></router-link>
            </v-list-item-title>
          </v-list-item>

          <v-list-item>
            <v-list-item-title>
              <router-link class="red--text text--lighten-3 mx-5"  to="/talking"><h1>Talking</h1></router-link>
            </v-list-item-title>
          </v-list-item>

          <v-list-item>
            <v-list-item-title>
              <router-link class="red--text text--lighten-3 mx-5"  to="/stories"><h1>Stories</h1></router-link>
            </v-list-item-title>
          </v-list-item> -->

          <v-list-item>
            <v-col
              class="d-flex mt-4"
              cols="12"
              sm="1"
              @click.stop="drawer = true"
            >
            <v-select
              v-model="select"
              :items="items"
              item-text="name"
              item-value="number"
              @input="hskChoose(select.number)"
              color="black"
              label="select"
              return-object
              single-line
            ></v-select>
            </v-col>
          </v-list-item>

          <v-list-item>
            <v-list-item-title>
              <router-link class="red--text text--lighten-3 mx-5"  to="/shop"><h3>Shop</h3></router-link>
            </v-list-item-title>
          </v-list-item>

          <v-list-item>
            <v-list-item-title>
              <router-link class="red--text text--lighten-3 mx-5"  to="/about"><h3>Why Maloha?</h3></router-link>
            </v-list-item-title>
          </v-list-item>

        </v-list-item-group>
      </v-list>
    </v-navigation-drawer>

    <v-main style="background-color:  #fffaf0;">
      <router-view/>
    </v-main>
    <v-footer class="mt-0 orange lighten-4" >
      <v-row class="text-right my-1">
        <v-spacer></v-spacer>
        <!-- <v-col> -->
          <a href="mailto:Maloha<aan_marleen%40hotmail%2Ecom>?SUBJECT=Feedback%20Maloha&BODY=Thank%20you%20for%20sharing%20your%20feedback!%0D%0AWhat%20do%20you%20think%3F%0D%0A%0D%0AFeedback%3A" style="text-decoration:none;">
            <v-btn color="red accent-4 amber--text text--lighten-4" class="white--text">
              Feedback
            </v-btn>
          </a>
        <!-- </v-col> -->
        <!-- <v-col> -->
          <h4 class="mt-2 mx-5 red--text">We appreciate it!</h4> 
        <!-- </v-col> -->
        <!-- , {name: 'HSK 2', number: '2'}, {name: 'HSK 3', number:'3'} -->
      </v-row>
    </v-footer>
  </v-app>
</template>

<script>
import store from '@/store'

export default {
  name: 'App',

  data: () => ({
    drawer: false,
    group: null,
    select: {name: 'HSK 1', number: '1'},
    items: [{name: 'HSK 1', number: '1'}, {name: 'HSK 2', number: '2'}],
    page: '',
    change: false,
  }),
  computed:{
    totalScore() {
      return store.state.totalScore
    }
  },
  watch: {
    totalScore(){
      this.change = true
      setTimeout( () => {
        this.change = false
      }, 4000);
    },
    group () {
      this.drawer = false
    },
    // $route: function() {
    //   // Check if given route is true, if it is then hide Nav. 
    //   if (this.$route.path === "/games") {
    //     console.log("games")
    //     this.page = "Games"
    //   }else if (this.$route.path === "/stories"){
    //     console.log("Stories")
    //     this.page = "Stories"
    //   }else if (this.$route.path === "/songs"){
    //     console.log("Songs")
    //     this.page = "Songs"
    //   }else if (this.$route.path === "/talking"){
    //     console.log("Talking")
    //     this.page = "Talking"
    //   } else  {
    //     this.page = "Maloha"
    //     console.log("other")
    //   }
    // },
  },
  methods: {
    hskChoose(hskLevel) {
      store.commit("HSK", hskLevel);
    },
    stopPlaying() {
      store.commit("STOPPLAYING");
    }
  }
};
</script>

<style lang="scss">
// .v-image__image {
//     box-shadow: 0px 0px 8px 8px white inset;
// }
  .hsk-style .v-select__selection.v-select__selection--comma{
    padding-bottom:0px;
    // padding-top:5px;
    color: #ffecb3;
  }
  .hsk-style .mdi-menu-down::before {
    content: "\F035D";
    // padding-top: 15px;
    // font-size: 30px;
  }

  .router:hover {
    // color:blue;
    background-color: rgb(170, 0, 0);
  }
  .shop{
    background-color: #910000
  }
  .points{
    width:70px; background-color: #FFECB3; color: #bd1300;
  }
  .shopPadding{
    padding-top: 4px;
  }

  @keyframes example {
  0%   { color:  #bd1300 !important; background-color: #FFECB3; width: 100px; height: 50px; padding: 6px;}
  20%  { color: #ffffb8 !important; background-color: rgb(168, 0, 0); width: 70px; height: 35px; padding: 0px; }
  40%  { color:  #bd1300 !important; background-color: #FFECB3; width: 100px; height: 50px; padding: 6px;}
  60%  { color: #ffffb8 !important; background-color: rgb(168, 0, 0); width: 70px; height: 35px;padding: 0px;}
  80%  { color:  #bd1300 !important; background-color: #FFECB3; width: 100px; height: 50px; padding: 6px;}
  100% { color:  #bd1300 !important; background-color: #FFECB3; width: 70px; height: 35px;padding: 0px;}
}

/* The element to apply the animation to */
  .animation {
    animation-name: example !important;
    animation-duration: 4s;
    color:rgb(211, 134, 134);
  }

  @keyframes example2 {
  0%   {padding-top: 12px;}
  20%  {padding-top: 4px;}
  40%  {padding-top: 12px;}
  60% {padding-top: 4px;}
  80% {padding-top: 12px;}
  100% {padding-top: 4px;}
}

/* The element to apply the animation to */
  .animation2 {
    animation-name: example2;
    animation-duration: 4s;
  }

  @keyframes exampleMobile {
  0%   { color:  #bd1300 !important; background-color: #FFECB3; width: 80px; height: 40px; padding: 3px;}
  20%  { color: #ffffb8 !important; background-color: rgb(168, 0, 0); width: 70px; height: 35px; padding: 0px; }
  40%  { color:  #bd1300 !important; background-color: #FFECB3; width: 80px; height: 40px; padding: 3px;}
  60%  { color: #ffffb8 !important; background-color: rgb(168, 0, 0); width: 70px; height: 35px;padding: 0px;}
  80%  { color:  #bd1300 !important; background-color: #FFECB3; width: 80px; height: 40px; padding: 3px;}
  100% { color:  #bd1300 !important; background-color: #FFECB3; width: 70px; height: 35px;padding: 0px;}
}

/* The element to apply the animation to */
  .animationMobile {
    animation-name: exampleMobile !important;
    animation-duration: 4s;
  }
</style>
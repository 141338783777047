import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'

Vue.use(VueRouter)

const routes = [
  { path: '*', component: Home },
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  {
    path: '/games',
    name: 'Games',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/Games.vue')
  },
  {
    path: '/stories',
    name: 'Stories',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/Stories.vue')
  },
  {
    path: '/songs',
    name: 'Songs',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/Songs.vue')
  },  {
    path: '/talking',
    name: 'Talking',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/Talking.vue')
  },
  { 
    path: '/talking/:id',
    name: 'Conversation',
    component: () => import(/* webpackChunkName: "about" */ '../views/TalkPage.vue')
  },
  { 
    path: '/memory',
    name: 'Memory',
    component: () => import(/* webpackChunkName: "about" */ '../views/Memory.vue')
  },
  { 
    path: '/memory2',
    name: 'Reading',
    component: () => import(/* webpackChunkName: "about" */ '../views/Memory2.vue')
  },
  { 
    path: '/memory3',
    name: 'Listening',
    component: () => import(/* webpackChunkName: "about" */ '../views/Memory3.vue')
  },
  { 
    path: '/searchgame',
    name: 'Search Game',
    component: () => import(/* webpackChunkName: "about" */ '../views/SearchGame.vue')
  },
  { 
    path: '/onestory',
    name: 'Rice and tea',
    component: () => import(/* webpackChunkName: "about" */ '../views/OneStory.vue')
  },
  { 
    path: '/storytwo',
    name: 'Supermarket',
    component: () => import(/* webpackChunkName: "about" */ '../views/StoryTwo.vue')
  },
  { 
    path: '/storythree',
    name: 'Meeting people',
    component: () => import(/* webpackChunkName: "about" */ '../views/StoryThree.vue')
  },
  { 
    path: '/storyfour',
    name: 'Signs',
    component: () => import(/* webpackChunkName: "about" */ '../views/StoryFour.vue')
  },
  { 
    path: '/land',
    name: 'Topography',
    component: () => import(/* webpackChunkName: "about" */ '../views/Land.vue')
  },
  { 
    path: '/write',
    name: 'Characters',
    component: () => import(/* webpackChunkName: "about" */ '../views/Write.vue')
  },
  { 
    path: '/about',
    name: 'About',
    component: () => import(/* webpackChunkName: "about" */ '../views/About.vue')
  },
  { 
    path: '/shop',
    name: 'Shop',
    component: () => import(/* webpackChunkName: "about" */ '../views/Shop.vue')
  },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
